import React from 'react';
import { Col, Input, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { TbMathEqualLower } from 'react-icons/tb';
import { MenuOutlined } from '@ant-design/icons';
import { Delete } from 'react-iconly';
import urlPageImages from '../../../../urls/urlPageImages';
import encodeUnsafeCharacters from '../../../../lib/encodeUnsafeCharacters';
import appColors from '../../../../const/colors';
import { imageStatuses } from '../../../../const/imageStatuses';
import { recommendedTypes } from '../../../../const/recommendedTypes';
import { Permissions } from '../../../../const/permissions';
import { hasRights } from '../../../../components/HasRights/HasRights';
import { restoreTapsDifficultyValues } from '../../forms/FormFeedsFilter/FormFeedsFilter.const';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import CollapsibleText from '../../../../components/CollapsibleText';



export const imagesImportDeadline = '2021-01-01';

export const makeImagesUrl = (record, taps_from, taps_to, formats, excludedHashtagIds) => {
  let searchParams = `?page=1&limit=48&search=status:${imageStatuses.ready_for_release};category:${record.category_id};imported_from:${imagesImportDeadline};is_main_category:1`;

  searchParams += `;tags:${excludedHashtagIds};is_exclude_tags:1;recommended_types:${recommendedTypes.Daily},${recommendedTypes.Packs};is_exclude_recommended_types:1`;

  if (taps_from) {
    searchParams += `;taps_from:${taps_from}`;
  }

  if (taps_to) {
    searchParams += `;taps_to:${taps_to}`;
  }

  if (formats) {
    searchParams += `;formats:${formats}`;
  }

  return urlPageImages() + encodeUnsafeCharacters(searchParams);
};

export const restrictToTableBounds = ({ transform, active, rects }) => {
  if (!active || !rects || !rects.containerNodeRect) return transform;

  const isRow = active.data.current?.type === 'row';
  const isColumn = active.data.current?.type === 'column';

  const containerElement = isRow ? rects.containerNode.querySelector('tbody')
    : isColumn ? rects.containerNode.querySelector('thead') : null;

  if (containerElement && transform) {
    const containerRect = containerElement.getBoundingClientRect();

    if (isRow) {
      const { top, bottom } = containerRect;

      return {
        ...transform,
        y: Math.min(Math.max(transform.y, 0), bottom - top),
      };
    }

    if (isColumn) {
      const { left, right } = containerRect;

      return {
        ...transform,
        x: Math.min(Math.max(transform.x, 0), right - left),
      };
    }
  }

  return transform;
};

export const getCategoryLabelById = (categoryId, options) => {
  return options.find((category) => category.value === categoryId)?.label || '';
};

export const createTableColumn = ({
  colData = {},
  colKey,
  intl,
  monthReserveValue = 2,
  excludedHashtagIds = '',
  isReferenceTable = false,
  isEdit = false,
  handleChangeCellValue = () => {},
}) => {
  const { column_id, taps_from, taps_to, formats, ...restParams } = colData;
  const title = (
    <>
      {taps_from ?? <TbMathEqualLower className="remix-icon" size={14} />}
      {taps_from && taps_to ? '-' : ''}
      {taps_to ?? '+'}
    </>
  );

  const formatsArray = isArray(formats) ? formats : formats.split(',');
  const formatsString = !isArray(formats) ? formats : formats.join(',');
  const isEditReserveStatistics = isEdit && !isReferenceTable;
  const isEditReference = isReferenceTable && isEdit;

  return {
    align: 'center',
    width: 140,
    title: (
      <Col>
        {(taps_from || taps_to) && <div>{title}</div>}
        <Tooltip
          placement="top"
          title={formatsArray.map((format) => <div key={format}>{format}</div>)}
        >
          <div className="collapsible-text">{formatsArray.join(' & ')}</div>
        </Tooltip>
      </Col>
    ),
    modalTitle: (
      <span>
        {(taps_from || taps_to) && (
          <span className="hp-mr-4">
            (
            {title}
            )
          </span>
        )}
        {formatsArray.join(' & ')}
      </span>
    ),
    dataIndex: colKey,
    key: `col_${colKey}`,
    params: { dataKey: isEdit ? colKey : null, column_id, taps_from, taps_to, formats, ...restParams },
    sortable: !isReferenceTable,
    render: (text, record, index) => {
      const cellData = record[colKey];
      const cellValue = (isReferenceTable ? cellData?.benchmark : cellData?.value) || 0;
      const cellBenchmarkValue = (cellData?.benchmark ?? 0) * monthReserveValue;

      if (isEditReserveStatistics) {
        return null;
      }

      const cell = cellBenchmarkValue > cellValue ? (
        <Tag
          color="error"
          style={{
            marginRight: 'unset',
            color: hasRights([ Permissions.CONTENT.IMAGES.LIST ]) ? appColors.blue : appColors.error,
          }}
        >
          {cellValue}
        </Tag>
      ) : cellValue;

      if (isEditReference) {
        return (
          <Input
            type="number"
            min="0"
            max="9999"
            id={`cell_${index}_${colKey}`}
            size='small'
            data-row={index}
            data-col={colKey}
            style={{ width: '100%', textAlign: 'center' }}
            value={cellValue}
            onChange={(event) => handleChangeCellValue(event, index, colKey)}
            onWheel={(event) => event.currentTarget.blur()}
          />
        );
      }

      return (
        <div>
          {isReferenceTable ? cell : (
            <Tooltip
              placement="top"
              title={`${intl.formatMessage({ id: 'images-reserve-statistics-reference-value' })}: ${cellBenchmarkValue}`}
            >
              <div className='cursor-default'>
                {hasRights([ Permissions.CONTENT.IMAGES.LIST ]) ?
                  <Link to={makeImagesUrl(record, taps_from, taps_to, formatsString, excludedHashtagIds)} target='_blank'>
                    {cell}
                  </Link> : cell}
              </div>
            </Tooltip>
          )}
        </div>
      );
    },
  };
};

export const getInitialColumns = ({
  groupedData,
  isViewMode,
  filteredCategoriesKeys,
  isEdit,
  isReferenceTable,
  dynamicColumns,
  hasTotal,
  canUpdate,
  setRemovingItem,
  toggleRemoveItemModalOpen,
}) => {
  return [
    {
      align: 'center',
      sortable: false,
      width: 150,
      title: <IntlMessages id="ui-general-category" />,
      dataIndex: 'category_name',
      key: 'category_name',
      fixed: 'left',
      filters: isViewMode
        ? Object.values(groupedData())
          .sort((_a, _b) => _a.category_name.localeCompare(_b.category_name))
          .map((item) => {
            return {
              text: <CollapsibleText text={item.category_name} maxLength="16" />,
              value: item.key,
            };
          })
        : null,
      filteredValue: isViewMode ? filteredCategoriesKeys : [],
      onFilter: (value, record) => record.key === value,
      render: (value) => (
        <div className="reserve-statistics-table__category-row">
          {isEdit && !isReferenceTable && (
            <MenuOutlined style={{ cursor: 'grab', color: appColors.grey, marginRight: '10px' }} />
          )}
          <Tooltip placement="top" title={value}>
            <span className="hp-text-overflow-ellipsis">{value}</span>
          </Tooltip>
        </div>
      ),
    },
    ...dynamicColumns,
    ...(hasTotal ? [
      {
        title: <b><IntlMessages id="ui-general-total" /></b>,
        dataIndex: 'total',
        key: 'total',
        fixed: 'right',
        align: 'center',
        sortable: false,
        render: (text) => <b>{text}</b>,
        width: 140,
      },
    ] : []),
    ...(canUpdate ? [
      {
        title: <IntlMessages id="ui-general-action" />,
        key: 'action',
        align: 'center',
        fixed: 'right',
        width: 80,
        sortable: false,
        render: (item) => (
          <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
            <div>
              <Delete
                size={20}
                className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                onPointerDown={(event) => event.stopPropagation()}
                onClick={(event) => {
                  event.stopPropagation();
                  setRemovingItem({ ...item, type: 'row' });
                  toggleRemoveItemModalOpen();
                }}
              />
            </div>
          </Tooltip>
        ),
      } ] : []),
  ];
};

export const getColumnInitialValues = (updatingItem, columns) => {
  const column = updatingItem.key ? columns.find((col) => col.key === updatingItem.key) : {};

  if (!isEmpty(column)) {
    const preparedFormats = column?.params?.formats
      ? isArray(column.params.formats)
        ? column.params.formats
        : column.params.formats.split(',')
      : [];

    return {
      column_id: column?.params?.column_id ?? null,
      key: column.key,
      formats: preparedFormats,
      taps_from: column?.params.taps_from || null,
      taps_to: column?.params.taps_to || null,
      taps_difficulty: restoreTapsDifficultyValues({
        tapsFrom: column?.params.taps_from || null,
        tapsTo: column?.params.taps_to || null,
      }),
    };
  }

  return {
    column_id: null,
    key: null,
    formats: [],
    taps_from: null,
    taps_to: null,
    taps_difficulty: null,
  };
};
