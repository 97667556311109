import capitalize from 'lodash/capitalize';



const imageTypes = {
  image: 'image',
  bee: 'bee',
  sharp: 'sharp',
  cargo: 'cargo',
  blend: 'blend',
};

const imageTypeOptions = Object.entries(imageTypes)
  .map(([ key, value ]) => {
    return { label: capitalize(value), value: key };
  });


export { imageTypes, imageTypeOptions };
