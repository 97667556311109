import React from 'react';
import { ImagesReserveStatisticProvider } from '../../../contexts/ImagesReserveStatisticContext';
import PageImagesReserveStatisticsReferenceContent from './PageImagesReserveStatisticsReferenceContent';



const PageFeedsPlanningDetails = () => {
  return (
    <ImagesReserveStatisticProvider>
      <PageImagesReserveStatisticsReferenceContent />
    </ImagesReserveStatisticProvider>
  );
};

export default PageFeedsPlanningDetails;
