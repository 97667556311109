import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Tooltip } from 'antd';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Delete } from 'react-iconly';
import { MenuOutlined } from '@ant-design/icons';
import { PiPencil } from 'react-icons/pi';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import appColors from '../../../../../const/colors';



const ReserveStatisticsTableDraggableHeader = ({
  id,
  title,
  modalTitle,
  isEdit = false,
  canUpdate = false,
  handleDeleteItem = (_item) => {},
  handleUpdateItem = (_item) => {},
  toggleRemoveItemModalOpen = () => {},
  toggleEditItemModalOpen = () => {},
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
    data: { type: 'column' },
    disabled: !isEdit,
  });

  const style = {
    transform: transform ? CSS.Transform.toString(transform) : undefined,
    transition: transition || 'none',
    opacity: isDragging ? 0.5 : 1,
    cursor: isEdit ? (isDragging ? 'grabbing' : 'grab') : 'default',
  };

  return (
    <Row
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      ref={setNodeRef} {...(isEdit ? attributes : {})} {...(isEdit ? listeners : {})}
      justify={isEdit ? 'space-between' : 'center'}
      align="middle"
      style={{ height: '100%', ...style }}
      gutter={[ 8, 8 ]}
    >
      {isEdit && (
        <Col xs={24} lg={4} xl={3}>
          <MenuOutlined style={{ cursor: 'grab', color: appColors.grey }} />
        </Col>
      )}

      <Col xs={24} lg={16} xl={18}>{title}</Col>

      {canUpdate && (
        <Col xs={24} lg={4} xl={3}>
          <Row className="reserve-statistics-table__header-row">
            <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
              <div>
                <PiPencil
                  size={20}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-2"
                  onPointerDown={(event) => event.stopPropagation()}
                  onClick={(event) => {
                    event.stopPropagation();

                    handleUpdateItem({ key: id, type: 'column', title: modalTitle });
                    toggleEditItemModalOpen();
                  }}
                />
              </div>
            </Tooltip>

            <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
              <div>
                <Delete
                  size={20}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-2"
                  onPointerDown={(event) => event.stopPropagation()}
                  onClick={(event) => {
                    event.stopPropagation();

                    handleDeleteItem({ key: id, type: 'column', title: modalTitle });
                    toggleRemoveItemModalOpen();
                  }}
                />
              </div>
            </Tooltip>
          </Row>
        </Col>
      )}
    </Row>
  );
};

ReserveStatisticsTableDraggableHeader.propTypes = {
  id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  title: PropTypes.object,
  modalTitle: PropTypes.object,
  isEdit: PropTypes.bool,
  canUpdate: PropTypes.bool,
  handleDeleteItem: PropTypes.func,
  handleUpdateItem: PropTypes.func,
  toggleRemoveItemModalOpen: PropTypes.func,
  toggleEditItemModalOpen: PropTypes.func,
};

export default ReserveStatisticsTableDraggableHeader;
