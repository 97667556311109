const recommendedTypes = {
  Daily: 'Daily',
  Feed: 'Feed',
  Packs: 'Packs',
  'Social media': 'Social media',
};

const recommendedTypeOptions = Object.entries(recommendedTypes).map(([ key, value ]) => {
  return {
    label: value,
    value: key,
  };
});


export { recommendedTypes, recommendedTypeOptions };
