import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Yup from '../../../../vendor/yup';



export const validationSchema = Yup.lazy((values) => {
  const { taps_to, taps_from, released_from, released_to, colors_from, colors_to } = values;

  return Yup.object().shape({
    id: Yup.string()
      .min(1, 'Minimum 1 symbol required'),
    bee_id: Yup.string()
      .min(1, 'Minimum 1 symbol required'),
    is_main_category: Yup.boolean(),
    layouts: Yup.array().nullable(),
    hashtags: Yup.array().nullable(),
    is_exclude_hashtags: Yup.boolean(),
    recommended_types: Yup.array().nullable(),
    is_exclude_recommended_types: Yup.boolean(),
    formats: Yup.array().nullable(),
    released_from: Yup.string().test(
      'test_date_after',
      'Released from date must be same or before than released to',
      (value) => !isEmpty(released_to) && !isEmpty(value) ?
        moment(released_to).isSameOrAfter(value) : true,
    ).nullable(),
    released_to: Yup.string().test(
      'test_date_before',
      'Released to date must be same or after than released from',
      (value) => !isEmpty(released_from) && !isEmpty(value) ?
        moment(released_from).isSameOrBefore(value) : true,
    ).nullable(),
    taps_from: Yup.number().test(
      'check-taps-min',
      'Taps from must be same or before than taps to',
      (value) => value && taps_to ? Number(value) <= Number(taps_to) : true,
    ).nullable(),
    taps_to: Yup.number().test(
      'check-taps-max',
      'Taps to must be same or after than taps from',
      (value) => value && taps_from ? Number(value) >= Number(taps_from) : true,
    ).nullable(),
    colors_from: Yup.number().test(
      'check-colors-min',
      'Colors from must be same or before than colors to',
      (value) => value && colors_to ? Number(value) <= Number(colors_to) : true,
    ).nullable(),
    colors_to: Yup.number().test(
      'check-colors-max',
      'Colors to must be same or after than colors from',
      (value) => value && colors_from ? Number(value) >= Number(colors_from) : true,
    ).nullable(),
  });
});

export const getPredefinedTapsDifficultyValues = (value) => {
  switch (value) {
    case 'very_simple': return { taps_from: 1, taps_to: 299 };
    case 'simple': return { taps_from: 1, taps_to: 699 };
    case 'medium': return { taps_from: 700, taps_to: 999 };
    case 'hard': return { taps_from: 1000, taps_to: null };

    default: return { taps_from: null, taps_to: null };
  }
};

export const restoreTapsDifficultyValues = ({ tapsFrom, tapsTo }) => {
  if (tapsFrom || tapsTo) {
    if (tapsFrom === 1 && tapsTo === 299) return 'very_simple';
    if (tapsFrom === 1 && tapsTo === 699) return 'simple';
    if (tapsFrom === 700 && tapsTo === 999) return 'medium';
    if (tapsFrom === 1000) return 'hard';
  }
  return null;
};
