import React from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';



const ReserveStatisticsTableDraggableRow = ({ id, children, isEdit = false, isReferenceTable = false, ...restProps }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
    data: { type: 'row' },
    disabled: !isEdit || isReferenceTable,
  });

  const style = {
    ...restProps.style,
    transform: transform ? CSS.Transform.toString(transform) : undefined,
    transition,
    opacity: isDragging ? 0.8 : 1,
    cursor: isEdit && !isReferenceTable ? (isDragging ? 'grabbing' : 'grab') : 'default',
  };

  return (
    <tr
      ref={setNodeRef}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restProps} {...(isEdit ? attributes : {})} {...(isEdit ? listeners : {})}
      style={style}
    >
      {children}
    </tr>
  );
};

ReserveStatisticsTableDraggableRow.propTypes = {
  id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  children: PropTypes.node,
  isEdit: PropTypes.bool,
  isReferenceTable: PropTypes.bool,
};

export default ReserveStatisticsTableDraggableRow;
