import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Modal, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { RiArrowLeftLine, RiErrorWarningLine, RiInsertColumnLeft, RiInsertRowTop } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';
import urlPageImages from '../../../../../urls/urlPageImages';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../../const/permissions';
import urlPageImagesReserveStatistics from '../../../../../urls/urlPageImagesReserveStatistics';
import urlPageImagesReserveStatisticsEdit from '../../../../../urls/urlPageImagesReserveStatisticsEdit';
import urlPageImagesReserveStatisticsReference from '../../../../../urls/urlPageImagesReserveStatisticsReference';
import { PrivateComponent } from '../../../../../components/HasRights/HasRights';
import { useImagesReserveStatisticContext } from '../../../contexts/ImagesReserveStatisticContext';
import useToggle from '../../../../../app/hooks/useToggle';
import useCategoriesOptions from '../../../../../app/hooks/useCategoriesOptions';
import handleResponse from '../../../../../lib/handleResponse';
import ActionButton from '../../../../../layout/components/action-button';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import DocumentationTooltip from '../../../../../components/DocumentationTooltip/DocumentationTooltip';
import ReserveStatisticsTable from '../../../components/ReserveStatisticsTable';
import { useGetReserveStatisticsQuery, useUpdateReserveStatisticsTableStructureMutation } from '../../../api/reserveStatisticsApiSlice';



const PageImagesReserveStatistics = () => {
  const intl = useIntl();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [ monthReserveValue, setMonthReserveValue ] = useState(2);
  const [ initialData, setInitialData ] = useState([]);
  const [ isRedirectConfirmationModalOpen, setConfirmationModalOpen ] = useState(false);
  const [ navigationUrl, setNavigationUrl ] = useState('');
  const [ isActiveAddRowModal, toggleSetAddRowModal ] = useToggle(false);
  const [ isActiveAddColumnModal, toggleSetAddColumnModal ] = useToggle(false);
  const { tableStructure, initialTableStructure } = useImagesReserveStatisticContext();

  const isEditing = pathname.endsWith('edit');

  const {
    data = { data: [], customOptions: {} },
    isFetching,
  } = useGetReserveStatisticsQuery();

  const [
    updateReserveStatisticsTableStructure,
    { isLoading: isUpdatingReserveStatisticsTableStructure },
  ] = useUpdateReserveStatisticsTableStructureMutation();


  useEffect(() => {
    if (!isEmpty(data) && !isFetching) {
      setInitialData(data.data);
    }
  }, [ data, isFetching ]);


  const excludedHashtagIds = data?.customOptions?.excluded_hashtag_ids;
  const categoriesOptions = useCategoriesOptions(data?.customOptions?.categories);
  const formatsOptions = data?.customOptions?.formats?.map((item) => {
    return { label: capitalize(item), value: item };
  });

  const isDisabledAddRowBtn = Array.isArray(tableStructure?.rows) &&
    !categoriesOptions.some(
      (category) => !tableStructure.rows.some(
        (row) => row.category_id === category.value,
      ),
    );

  const isDisabledStructureSaveBtn = isEqual(initialTableStructure, tableStructure) ||
      !(tableStructure?.rows?.length && tableStructure.columns?.length);

  const handleCloseAddRowModal = () => toggleSetAddRowModal(false);
  const handleCloseAddColumnModal = () => toggleSetAddColumnModal(false);

  const handleSave = () => {
    updateReserveStatisticsTableStructure({ payload: tableStructure })
      .unwrap()
      .then((response) => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          () => {
            if (!isEmpty(response.data)) {
              setInitialData(response.data);
            }
          },
          () => navigate(urlPageImagesReserveStatistics()),
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        error,
      ));
  };

  const handleClickOnBreadcrumbInEditing = (event, url) => {
    event.preventDefault();

    if (isEditing && !isEqual(initialTableStructure, tableStructure)) {
      setConfirmationModalOpen(true);
      setNavigationUrl(url);
    } else {
      navigate(url);
    }
  };

  return (
    <>
      <Modal
        title={<IntlMessages id="ui-general-leave-changes-confirm-modal-title" />}
        centered
        visible={isRedirectConfirmationModalOpen}
        onOk={() => {
          setConfirmationModalOpen(false);
          navigate(navigationUrl);
        }}
        onCancel={() => setConfirmationModalOpen(false)}
      >
        <IntlMessages id="ui-general-leave-changes-confirm-message" />
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link
              onClick={(event) => handleClickOnBreadcrumbInEditing(event, urlPageImages())}
              to={urlPageImages()}
            >
              <IntlMessages id='images-breadcrumbs' />
            </Link>
          }
          breadCrumbParent2={isEditing ?
            <Link
              onClick={(event) => handleClickOnBreadcrumbInEditing(event, urlPageImagesReserveStatistics())}
              to={urlPageImagesReserveStatistics()}
            >
              <IntlMessages id='images-reserve-statistics-breadcrumbs' />
            </Link> : null}
          breadCrumbActive={
            <div>
              <IntlMessages id={!isEditing ? 'images-reserve-statistics-breadcrumbs' : 'ui-general-edit'} />
              <DocumentationTooltip link='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/1162739724/Reserve+statistics' />
            </div>
          }
        />

        <Col>
          <Space>
            {!isEditing ? (
              <>
                <PrivateComponent allowedPermissions={[ Permissions.CONTENT.IMAGES.RESERVE_STATISTICS.EDIT_TABLE_STRUCTURE ]}>
                  <ActionButton
                    className='hp-mr-8'
                    onClick={() => navigate(urlPageImagesReserveStatisticsEdit())}
                    title={intl.formatMessage({ id: 'images-reserve-statistics-configure-table' })}
                  />
                </PrivateComponent>

                <ActionButton
                  className='hp-mr-8'
                  onClick={() => navigate(urlPageImagesReserveStatisticsReference())}
                  title={intl.formatMessage({ id: 'images-reserve-statistics-view-reference' })}
                />

                <Select
                  style={{ width: 170 }}
                  className="single-line-select w-full"
                  name='month_reserve'
                  defaultValue={monthReserveValue}
                  onChange={(value) => setMonthReserveValue(value)}
                  value={monthReserveValue}
                  options={Array.from({ length: 5 }, (_, index) => ({
                    label: `${index + 1} ${intl.formatMessage({ id: 'images-reserve-statistics-reserve-value' })}`,
                    value: index + 1,
                  }))}
                />
              </>
            ) : (
              <>
                {!isEqual(initialTableStructure, tableStructure) ? (
                  <Popconfirm
                    title={<IntlMessages id="ui-general-cancel-confirmation" />}
                    placement="top"
                    onConfirm={() => navigate(urlPageImagesReserveStatistics())}
                    okText={<IntlMessages id="ui-general-ok" />}
                    cancelText={<IntlMessages id="ui-general-cancel" />}
                    icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                    okButtonProps={{ danger: true }}
                  >
                    <Button
                      className='hp-mr-8'
                      icon={<RiArrowLeftLine className="remix-icon hp-mr-8" />}
                    >
                      <IntlMessages id="ui-general-back-to-view" />
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button
                    onClick={() => navigate(urlPageImagesReserveStatistics())}
                    className='hp-mr-8'
                    icon={<RiArrowLeftLine className="remix-icon hp-mr-8" />}
                  >
                    <IntlMessages id="ui-general-back-to-view" />
                  </Button>
                )}

                {isDisabledAddRowBtn ? (
                  <Tooltip placement="top" title={<IntlMessages id="images-reserve-statistics-add-rows-disabled" />}>
                    <div>
                      <ActionButton
                        className='hp-mr-8'
                        disabled={isDisabledAddRowBtn}
                        onClick={() => {}}
                        icon={<RiInsertRowTop className="btn-icon-mr-1" />}
                        title={intl.formatMessage({ id: 'images-reserve-statistics-add-rows' })}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <ActionButton
                    className='hp-mr-8'
                    onClick={(event) => {
                      event.target?.closest('button').blur();
                      toggleSetAddRowModal();
                    }}
                    disabled={isUpdatingReserveStatisticsTableStructure}
                    icon={<RiInsertRowTop className="btn-icon-mr-1" />}
                    title={intl.formatMessage({ id: 'images-reserve-statistics-add-rows' })}
                  />
                )}

                <ActionButton
                  className='hp-mr-8'
                  onClick={(event) => {
                    event.target?.closest('button').blur();
                    toggleSetAddColumnModal();
                  }}
                  disabled={isUpdatingReserveStatisticsTableStructure}
                  icon={<RiInsertColumnLeft className="btn-icon-mr-1" />}
                  title={intl.formatMessage({ id: 'images-reserve-statistics-add-col' })}
                />

                {isDisabledStructureSaveBtn ? (
                  <Tooltip placement="top" title={<IntlMessages id={isEqual(initialTableStructure, tableStructure) ? 'images-reserve-statistics-save-table-same-structure-disabled' : 'images-reserve-statistics-save-table-empty-structure-disabled'} />}>
                    <Button
                      loading={isUpdatingReserveStatisticsTableStructure}
                      disabled={isDisabledStructureSaveBtn}
                      type="primary"
                      onClick={handleSave}
                    >
                      <IntlMessages id="ui-general-save" />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    loading={isUpdatingReserveStatisticsTableStructure}
                    disabled={isDisabledStructureSaveBtn}
                    type="primary"
                    onClick={handleSave}
                  >
                    <IntlMessages id="ui-general-save" />
                  </Button>
                )}
              </>
            )}
          </Space>
        </Col>
      </Row>

      <Row gutter={[ 16, 16 ]}>
        <Col className='hp-mb-16' />
      </Row>

      <Row gutter={[ 16, 16 ]}>
        <Col span={24}>
          <ReserveStatisticsTable
            isEdit={isEditing}
            isLoading={isFetching || isUpdatingReserveStatisticsTableStructure}
            initialData={initialData}
            monthReserveValue={monthReserveValue}
            categoriesOptions={categoriesOptions}
            formatsOptions={formatsOptions}
            excludedHashtagIds={excludedHashtagIds}
            isActiveAddRowModal={isActiveAddRowModal}
            isActiveAddColumnModal={isActiveAddColumnModal}
            handleCloseAddRowModal={handleCloseAddRowModal}
            handleCloseAddColumnModal={handleCloseAddColumnModal}
          />
        </Col>
      </Row>
    </>
  );
};

export default PageImagesReserveStatistics;
