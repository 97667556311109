import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';



const ImagesReserveStatisticContext = createContext({});

export const useImagesReserveStatisticContext = () => {
  return useContext(ImagesReserveStatisticContext);
};

export const ImagesReserveStatisticProvider = ({ children }) => {
  const [ tableStructure, setTableStructure ] = useState([]);
  const [ referenceData, setReferenceData ] = useState([]);
  const [ initialReferenceData, setInitialReferenceData ] = useState([]);
  const [ initialTableStructure, setInitialTableStructure ] = useState([]);

  const updateTableStructure = (values) => {
    setTableStructure(values);
  };

  const updateInitialTableStructure = (values) => {
    setInitialTableStructure(values);
  };

  const updateReferenceData = (values) => {
    setReferenceData(values);
  };

  const updateInitialReferenceData = (values) => {
    setInitialReferenceData(values);
  };

  const value = useMemo(() => {
    return {
      referenceData,
      initialReferenceData,
      tableStructure,
      initialTableStructure,
      updateReferenceData,
      updateInitialReferenceData,
      updateTableStructure,
      updateInitialTableStructure,
    };
  }, [ referenceData, initialReferenceData, tableStructure, initialTableStructure ]);

  return (
    <ImagesReserveStatisticContext.Provider value={value}>
      {children}
    </ImagesReserveStatisticContext.Provider>
  );
};

ImagesReserveStatisticProvider.propTypes = {
  children: PropTypes.node,
};
