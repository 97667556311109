const images = {
  'images-breadcrumbs': 'Images',
  'images-reserve-statistics-breadcrumbs': 'Reserve statistics',
  'images-reserve-statistics-reference-value': 'Reference value',
  'images-reserve-statistics-reserve-value': 'month reserve',
  'images-reserve-statistics-configure-table': 'Configure table',
  'images-reserve-statistics-view-reference': 'Reference values',
  'images-reserve-statistics-add-rows': 'Add rows',
  'images-reserve-statistics-add-rows-disabled': 'All existing categories have already been added',
  'images-reserve-statistics-save-table-same-structure-disabled': 'There are no changes to save the data',
  'images-reserve-statistics-save-table-empty-structure-disabled': 'To save the data, there must be rows and columns available. Please make changes',
  'images-reserve-statistics-add-edit-column-empty-message': 'Please fill in at least one field',
  'images-reserve-statistics-add-col': 'Add column',
  'images-reserve-statistics-edit-col': 'Edit column',
  'images-reserve-statistics-remove-row': 'Remove row',
  'images-reserve-statistics-remove-col': 'Remove column',
  'images-reserve-statistics-reference-notification': 'Reference values for the 1 month reserve',
  'form-add-rows-notification': 'The list for addition will only show the categories that have not been added yet',
  'form-add-rows-success-notification': 'Rows added successfully',
  'form-add-column-success-notification': 'Column added successfully',
  'form-edit-column-success-notification': 'Column data successfully updated',
  'form-remove-rows-success-notification': 'Row removed successfully',
  'form-remove-columns-success-notification': 'Column removed successfully',
  'images-reserve-statistics-remove-row-confirmation': 'Are you sure you want to remove from the statistics row',
  'images-reserve-statistics-remove-column-confirmation': 'Are you sure you want to remove from the statistics column',
};

export default images;
