import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const NewsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'DynamicNews', 'DynamicNewsPriority' ],
  endpoints: (builder) => {
    return ({
      getDynamicNews: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/dynamic-news${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
            pagination: response?.meta?.pagination,
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'DynamicNews' ],
      }),
      getSpecificDynamicNews: builder.query({
        query: ({ newsId, includes }) => ({ url: `/${Config.VITE_HC_API_VERSION}/dynamic-news/${newsId}${includes}` }),
        transformResponse: (response) => response.data,
        providesTags: () => [ 'DynamicNewsItem' ],
      }),
      createDynamicNews: builder.mutation({
        query: (values) => ({
          url: `/${Config.VITE_HC_API_VERSION}/dynamic-news`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'DynamicNews', 'Template' ],
      }),
      updateDynamicNews: builder.mutation({
        query: ({ id, values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/dynamic-news/${id}`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'DynamicNews', 'Template', 'DynamicNewsItem' ],
      }),
      getDynamicNewsPriority: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/dynamic-news/settings/priorities${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
          };
        },
        providesTags: [ 'DynamicNewsPriority', 'DynamicNews' ],
      }),
      updateDynamicNewsPriority: builder.mutation({
        query: (values) => ({
          url: `/${Config.VITE_HC_API_VERSION}/dynamic-news/settings/priorities`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'DynamicNewsPriority' ],
      }),
      deleteDynamicNews: builder.mutation({
        query: (id) => ({
          url: `/${Config.VITE_HC_API_VERSION}/dynamic-news/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [ 'DynamicNews', 'Template' ],
      }),
      unpublishDynamicNews: builder.mutation({
        query: (id) => ({
          url: `/${Config.VITE_HC_API_VERSION}/dynamic-news/${id}/unpublish`,
          method: 'POST',
        }),
        invalidatesTags: () => [ 'DynamicNews', 'DynamicNewsPriority', 'DynamicNewsItem' ],
      }),
    });
  },
});

export const {
  useGetDynamicNewsQuery,
  useGetSpecificDynamicNewsQuery,
  useCreateDynamicNewsMutation,
  useUpdateDynamicNewsMutation,
  useDeleteDynamicNewsMutation,
  useGetDynamicNewsPriorityQuery,
  useUpdateDynamicNewsPriorityMutation,
  useUnpublishDynamicNewsMutation,
} = NewsApiSlice;
