import React from 'react';
import { Form, FormItem, InputNumber, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Row, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import Yup from '../../../../vendor/yup';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import getNotEmptyObjectValues from '../../../../lib/getNotEmptyObjectValues';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { getPredefinedTapsDifficultyValues } from '../FormFeedsFilter/FormFeedsFilter.const';



const { Option } = Select;

const validationSchema = Yup.lazy((values) => {
  const { taps_to, taps_from } = values;

  return Yup.object().shape({
    formats: Yup.array().nullable(),
    taps_from: Yup.number().test(
      'check-taps-min',
      'Taps from must be same or before than taps to',
      (value) => value && taps_to ? Number(value) <= Number(taps_to) : true,
    ).nullable(),
    taps_to: Yup.number().test(
      'check-taps-max',
      'Taps to must be same or after than taps from',
      (value) => value && taps_from ? Number(value) >= Number(taps_from) : true,
    ).nullable(),
  });
});

const FormAddEditReserveStatisticsTableColumn = ({
  onSubmit = () => {},
  onCancel = () => {},
  isActiveEditItemModal,
  initialValues = {},
  formatsOptions = [],
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      initialErrors={[]}
      onSubmit={onSubmit}
      onReset={onCancel}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, dirty, setFieldValue, setFieldTouched, isValid } = props;
        const nonEmptyFieldsCount = Object.keys(getNotEmptyObjectValues(values))?.length;
        const isEmptyForm = isActiveEditItemModal ? nonEmptyFieldsCount === 1 : nonEmptyFieldsCount === 0;

        return (
          <Form
            className="images-filter w-full"
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  label={intl.formatMessage({ id: 'content-images-format-label' })}
                  name='formats'
                >
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    name="formats"
                    placeholder={intl.formatMessage({ id: 'content-images-format-label-placeholder' })}
                    options={formatsOptions}
                    filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'content-images-taps-from-label' })}
                  name='taps_from'
                >
                  <InputNumber
                    min={0}
                    value={values?.taps_from ? Number(values?.taps_from) : null}
                    name="taps_from"
                    onChange={(value) => {
                      setFieldValue('taps_difficulty', null);
                      setFieldValue('taps_from', value || null);
                    }}
                    className="w-full"
                    placeholder={intl.formatMessage({ id: 'ui-general-min' })}
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'content-images-taps-to-label' })}
                  name='taps_to'
                >
                  <InputNumber
                    min={0}
                    value={values?.taps_to ? Number(values?.taps_to) : null}
                    name="taps_to"
                    onChange={(value) => {
                      setFieldValue('taps_difficulty', null);
                      setFieldValue('taps_to', value || null);
                    }}
                    className="w-full"
                    placeholder={intl.formatMessage({ id: 'ui-general-max' })}
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem
                  name="taps_difficulty"
                  className="ant-form-item-col stretched"
                  label={
                    <Row className='w-full' justify="space-between" align="middle">
                      <IntlMessages id="content-images-taps-difficulty-label" />
                      <Tooltip placement="topRight" title={<IntlMessages id="feeds-form-difficulty-tooltip" />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Select
                    name="taps_difficulty"
                    allowClear
                    placeholder={intl.formatMessage({ id: 'content-images-taps-difficulty-label-placeholder' })}
                    onChange={(value) => {
                      const defaultTaps = getPredefinedTapsDifficultyValues(value);

                      setFieldValue('taps_difficulty', value || null);
                      setFieldValue('taps_from', defaultTaps.taps_from);
                      setFieldValue('taps_to', defaultTaps.taps_to);
                      setFieldTouched('taps_from', true);
                      setFieldTouched('taps_to', true);
                    }}
                    filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                  >
                    <Option value='very_simple'>
                      <IntlMessages id="feeds-form-very-simple-difficulty" />
                      &nbsp;
                      (1-299)
                    </Option>
                    <Option value='simple'>
                      <IntlMessages id="feeds-form-simple-difficulty" />
                      &nbsp;
                      (1-699)
                    </Option>
                    <Option value='medium'>
                      <IntlMessages id="feeds-form-medium-difficulty" />
                      &nbsp;
                      (700-999)
                    </Option>
                    <Option value='hard'>
                      <IntlMessages id="feeds-form-hard-difficulty" />
                      &nbsp;
                      (1000-&infin;)
                    </Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>
            {isEmptyForm && (
              <Row gutter={[ 16, 16 ]} justify='center'>
                <Col>
                  <Alert
                    className='hp-px-36 hp-py-8 hp-mb-24'
                    message={<IntlMessages id='images-reserve-statistics-add-edit-column-empty-message' />}
                    type="error"
                  />
                </Col>
              </Row>
            )}
            <Row gutter={[ 16, 16 ]} justify='center'>
              <Col>
                <Button
                  type='secondary'
                  onClick={onCancel}
                >
                  <IntlMessages id="ui-general-cancel" />
                </Button>
              </Col>
              <Col>
                <SubmitButton disabled={!dirty || !isValid || isEmptyForm}>
                  <IntlMessages id="ui-general-save" />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormAddEditReserveStatisticsTableColumn.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isActiveEditItemModal: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  values: PropTypes.shape({
    formats: PropTypes.array,
    taps_from: PropTypes.number,
    taps_to: PropTypes.number,
  }),
  formatsOptions: PropTypes.array,
};

export default FormAddEditReserveStatisticsTableColumn;
