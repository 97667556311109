import React from 'react';
import { Button, Col, Dropdown, Menu, Popconfirm, Row, Space } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MdOutlineUnpublished } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { PiGear, PiPencilSimpleLine, PiTrashSimple } from 'react-icons/pi';
import { RiArrowLeftLine } from 'react-icons/ri';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { PrivateComponent } from '../../../../../components/HasRights/HasRights';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../../const/permissions';
import urlPageDynamicNewsEdit from '../../../../../urls/urlPageDynamicNewsEdit';
import urlPageDynamicNewsPriority from '../../../../../urls/urlPageDynamicNewsPriority';
import urlPageDynamicNews from '../../../../../urls/urlPageDynamicNews';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import handleResponse from '../../../../../lib/handleResponse';
import DynamicNewsDetailsCard from '../../../components/DynamicNewsDetailsCard';
import mkPreparedTemplateData from '../../../utils/mkPreparedTemplateData';
import { TYPE_DICTIONARY } from '../../../../Template builder/const/templates';
import { DATE_FORMAT } from '../../../../../const/system';
import { useGetDictionaryQuery } from '../../../../Translations/api/dictionariesApiSlice';
import { useGetLanguagesQuery } from '../../../../Translations/api/languagesApiSlice';
import {
  useDeleteDynamicNewsMutation,
  useGetSpecificDynamicNewsQuery,
  useUnpublishDynamicNewsMutation,
} from '../../../api/dynamicNewsApiSlice';



const PageDynamicNewsDetails = () => {
  const { newsId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();

  const { data: dynamicNews = {}, isFetching } = useGetSpecificDynamicNewsQuery({ newsId, includes: '?include=dynamic_data' });

  const dictionaryField = dynamicNews?.dynamic_data?.data?.find((item) => item.type === TYPE_DICTIONARY);

  const { data: newsDictionary = {} } = useGetDictionaryQuery({
    id: dictionaryField?.value,
    include: 'translations,structure,structure.fields.translations',
  }, { skip: !dictionaryField?.value });

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({
    queryParams: 'limit=0',
  }, { skip: !dictionaryField });

  const [ unpublish, { isLoading: isUnpublishDynamicNewsLoading } ] = useUnpublishDynamicNewsMutation();
  const [ deleteDynamicNews, { isLoading: isDynamicNewsDeleting } ] = useDeleteDynamicNewsMutation();

  const data = mkPreparedTemplateData({
    templateData: dynamicNews?.template?.data?.fields?.data,
    dynamicData: dynamicNews?.dynamic_data?.data,
    dictionary: newsDictionary,
    languages: languages?.languages,
  });

  const handleUnpublish = () => {
    unpublish(newsId)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-unpublished-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-unpublish-failed', [], error));
  };

  const handleDelete = (id) => {
    deleteDynamicNews(id)
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-deleted-successfully',
        [
          () => navigate(urlPageDynamicNews()),
        ],
      ))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const actionItems = (
    <Menu>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.PRIORITY ]}>
        {Boolean(dynamicNews.released_at) && (
          <Menu.Item
            key="priority"
            icon={<PiGear size={18} />}
            onClick={() => {
              const preparedData = dayjs(dynamicNews.released_at).format(DATE_FORMAT);

              return navigate({
                pathname: urlPageDynamicNewsPriority(),
                search: `?search=date%3A${preparedData}`,
              });
            }}
          >
            <IntlMessages id="ui-general-priority" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.EDIT ]}>
        <Menu.Item
          key="edit"
          icon={<PiPencilSimpleLine size={18} />}
          onClick={() => navigate(urlPageDynamicNewsEdit({ newsId: dynamicNews.id }))}
        >
          <IntlMessages id="ui-general-edit" />
        </Menu.Item>
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.EDIT ]}>
        {Boolean(dynamicNews?.is_published) && (
          <Menu.Item
            key="unpublish"
            icon={<MdOutlineUnpublished size={18} />}
            onClick={handleUnpublish}
          >
            <IntlMessages id="ui-general-unpublish" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.DELETE ]}>
        {!dynamicNews.is_published &&
          <Popconfirm
            title={<IntlMessages id="ui-general-delete-confirm-message" />}
            placement="top"
            onConfirm={() => {
              handleDelete(dynamicNews.id);
            }}
          >
            <Menu.Item
              key="delete"
              icon={<PiTrashSimple size={18} />}
              onClick={() => {}}
            >
              <IntlMessages id="ui-general-delete" />
            </Menu.Item>
          </Popconfirm>}
      </PrivateComponent>
    </Menu>
  );

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageDynamicNews()}>
              <IntlMessages id='dynamic-news-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='dynamic-news-preview-breadcrumbs' />}
        />

        <Col md={10} span={24} className='text-end'>
          <Space size='middle'>
            <PrivateComponent
              allowedPermissions={[
                Permissions.CONTENT.NEWS.EDIT,
                Permissions.CONTENT.NEWS.DELETE,
              ]}
            >
              <Dropdown overlay={actionItems}>
                <Button onClick={(ev) => ev.preventDefault()}>
                  <Space size='middle'>
                    <IntlMessages id='ui-general-action' />
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </PrivateComponent>

            <ActionButton
              title={intl.formatMessage({ id: 'ui-general-back-to-list' })}
              icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
              onClick={() => {
                navigate(urlPageDynamicNews());
              }}
            />
          </Space>
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <DynamicNewsDetailsCard
            data={dynamicNews}
            isLoadingCard={isUnpublishDynamicNewsLoading || isDynamicNewsDeleting}
            isFetching={isFetching}
            newsDictionary={newsDictionary}
            languages={languages?.languages}
            templateCardsData={data}
          />
        </Col>
      </Row>
    </>
  );
};

export default PageDynamicNewsDetails;
