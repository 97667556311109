import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { init } from '@sentry/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { store } from './app/store';
import App from './App';
import Config from './configs/appConfig';
import AppContextProvider from './app/context/AppContext';
import './assets/icons/remixicon.css';
import './assets/less/yoda-theme.less';
import { disableAntdFutureVersionWarnings } from './lib/disableAntdFutureVersionWarnings';



if (Config.VITE_APP_ENVIRONMENT === 'local') {
  disableAntdFutureVersionWarnings();
}

const root = createRoot(document.getElementById('root'));

init({
  dsn: Config.VITE_SENTRY_DSN,
  environment: Config.VITE_APP_ENVIRONMENT ?? 'local',
});

root.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <GoogleOAuthProvider clientId={Config.VITE_GOOGLE_CLIENT_ID}>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </GoogleOAuthProvider>
    </Provider>
  </Suspense>,
);

// eslint-disable-next-line no-console
console.log('Frontend version:', Config.LAST_COMMIT_DATA);
