const logs = {
  'logs-breadcrumbs': 'Logs',
  'log-details-breadcrumbs': 'Log details',
  'logs-filter-title': 'Filter logs',
  'logs-table-event-col': 'Event',
  'logs-table-message-col': 'Message',
  'logs-form-user-placeholder': 'Specify user',
  'logs-form-event-placeholder': 'Specify event',
  'logs-form-message-placeholder': 'Specify message',
  'log-details-title': 'Log details',
  'log-details-user-name': 'User name',
  'log-details-content-id': 'Content ID',
  'log-details-log-id': 'Log ID',
  'log-details-model': 'Model',
  'log-details-diff': 'Difference',
  'log-details-diff-before': 'Before:',
  'log-details-diff-after': 'List of changes:',
};

export default logs;
