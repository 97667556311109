import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';



export const ReserveStatisticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => {
    return ({
      getReserveStatistics: builder.query({
        query: () => {
          return ({ url: `/${Config.VITE_HC_API_VERSION}/reserve-statistics` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            customOptions: response?.meta?.custom,
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'ReserveStatistics' ],
        keepUnusedDataFor: 1,
      }),
      updateReserveStatisticsTableStructure: builder.mutation({
        query: ({ payload }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/reserve-statistics/table/structure`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: () => [ 'ReserveStatistics' ],
      }),
      updateReserveStatisticsReference: builder.mutation({
        query: ({ payload }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/reserve-statistics/benchmarks`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: () => [ 'ReserveStatistics' ],
      }),
    });
  },
});

export const {
  useGetReserveStatisticsQuery,
  useUpdateReserveStatisticsTableStructureMutation,
  useUpdateReserveStatisticsReferenceMutation,
} = ReserveStatisticsApiSlice;
