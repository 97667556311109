import React from 'react';
import PropTypes from 'prop-types';
import { Card, Skeleton } from 'antd';
import { useIntl } from 'react-intl';
import Spinner from '../../../../../components/Spinner';



const ReserveStatisticsTableLayout = ({ isLoading = false, children }) => {
  const intl = useIntl();

  return (
    <div>
      {isLoading ? (
        <Spinner spinning tip={intl.formatMessage({ id: 'ui-general-loading' })}>
          <Skeleton active paragraph={{ rows: 30 }}>
            <Card style={{ height: 'calc(100vh - 240px)' }}>
              {children}
            </Card>
          </Skeleton>
        </Spinner>
      ) : children}
    </div>
  );
};

ReserveStatisticsTableLayout.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default ReserveStatisticsTableLayout;
