import React from 'react';
import PageImagesReserveStatisticsContent from './PageImagesReserveStatisticsContent';
import { ImagesReserveStatisticProvider } from '../../../contexts/ImagesReserveStatisticContext';



const PageFeedsPlanningDetails = () => {
  return (
    <ImagesReserveStatisticProvider>
      <PageImagesReserveStatisticsContent />
    </ImagesReserveStatisticProvider>
  );
};

export default PageFeedsPlanningDetails;
