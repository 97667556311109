import React from 'react';
import { Form, FormItem, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import Yup from '../../../../vendor/yup';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const validationSchema = Yup.object().shape({
  categories: Yup.array().nullable(),
});

const FormAddReserveStatisticsTableRows = ({
  onSubmit,
  onCancel,
  categoriesOptions = [],
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{ categories: null }}
      initialErrors={[]}
      onSubmit={onSubmit}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, resetForm, dirty } = props;

        return (
          <Form layout="vertical">
            <Col span={24}>
              <FormItem
                label={intl.formatMessage({ id: 'collection-form-categories-title' })}
                name='categories'
              >
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  name="categories"
                  placeholder={intl.formatMessage({ id: 'ui-general-choose-categories' })}
                  options={categoriesOptions}
                  filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                />
              </FormItem>
            </Col>

            <Alert
              className='hp-mb-24'
              type="warning"
              message=""
              description={intl.formatMessage({ id: 'form-add-rows-notification' })}
            />

            <Row gutter={[ 16, 16 ]} justify='center'>
              <Col>
                <Button
                  type='secondary'
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  <IntlMessages id="ui-general-cancel" />
                </Button>
              </Col>
              <Col>
                <SubmitButton disabled={!dirty || !values.categories?.length}>
                  <IntlMessages id="ui-general-save" />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormAddReserveStatisticsTableRows.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  categoriesOptions: PropTypes.array,
  values: PropTypes.shape({
    categories: PropTypes.array,
  }),
};

export default FormAddReserveStatisticsTableRows;
